<template>
  <v-dialog
    v-model="showNote"
    persistent
    max-width="500px"
  >
    <v-card class="rounded-lg">
      <v-card-title>
        <div class="d-flex align-center">
          <v-icon
            color="primary"
            size="40"
          >
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
          <div class="align-center px-1">
            {{ $t('PleaseNote') }}
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="closeNote">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>
      <v-card-subtitle class="text-base font-weight-bold">
        {{ $t('DateOrTimeChanged') }}
      </v-card-subtitle>
      <v-card-text class="text-base">
        <div class="d-flex">
          <v-icon
            color="primary"
            size="25"
          >
            {{ icons.mdiExclamationThick }}
          </v-icon>
          <div>
            {{ $t('TimingCanChange') }}
          </div>
        </div>
        <div class="d-flex py-2">
          <v-icon
            color="primary"
            size="25"
          >
            {{ icons.mdiExclamationThick }}
          </v-icon>
          <div>
            {{ $t('TemplatesNeedManualEdit') }}
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex align-center justify-center py-5">
        <v-btn
          color="primary"
          outlined
          rounded
          @click="moveToNextStep"
        >
          {{ $t('Ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiAlertCircleOutline, mdiExclamationThick } from '@mdi/js'

export default {
  setup(props, { emit }) {
    const showNote = ref(true)

    const moveToNextStep = () => {
      showNote.value = false
      emit('submit')
    }

    const closeNote = () => {
      emit('close')
    }

    return {
      showNote,
      moveToNextStep,
      closeNote,

      icons: {
        mdiClose,
        mdiAlertCircleOutline,
        mdiExclamationThick,
      },
    }
  },
}
</script>
