export default function useLanguages() {
  const LanguageList = [
    { value: 'hebrew', lang: 'he' },
    { value: 'english', lang: 'en' },
    { value: 'arabic', lang: 'ar' },
    { value: 'russian', lang: 'ru' },
  ]

  return {
    LanguageList,
  }
}
