export default function useWorkingDays(startDate, workingDays) {
  const date = new Date(startDate)
  let daysAdded = 0

  while (daysAdded < workingDays) {
    date.setDate(date.getDate() + 1)

    // Skip Saturdays
    if (date.getDay() !== 6) {
      daysAdded += 1
    }
  }

  return date.toISOString().substr(0, 10)
}
