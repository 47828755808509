<template>
  <div>
    <v-dialog
      v-model="showCropper"
      width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('EditInvitation') }}
        </v-card-title>
        <SlimCropper
          ref="cropper"
          :cropper-options="{
            cropBoxMovable: false,
            aspectRatio: 12/16,
          }"
          class="slimCrop"
          :src="stepData.inputImgUrl"
          @mousewheel.native="handleMouseWheel"
        ></SlimCropper>

        <v-card-actions class="d-flex justify-center align-center pa-2">
          <v-btn
            class="ma-2"
            rounded
            color="primary"
            @click="submitCrop"
          >
            {{ $t('Ok') }}
          </v-btn>
          <v-btn
            class="ma-2"
            rounded
            color="primary"
            outlined
            @click="hideCrop"
          >
            {{ $t('Cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card
      color="transparent"
      class="form-card mb-8"
      height="400px"
      flat
    >
      <v-card-title class="mt-0 pt-0 primary--text align-center justify-center">
        {{ $t('InvitationToEvent') }}
      </v-card-title>
      <v-card-subtitle class="align-center justify-center text-center">
        {{ $t('invitationUploadOption') }}
      </v-card-subtitle>
      <v-card-text
        class="mt-3 justify-center align-center text-canter"
        style="text-align: -webkit-center;"
      >
        <v-form
          ref="step3Form"
        >
          <v-card
            max-height="300px"
            max-width="225px"
            class="d-flex justify-center align-center"
            flat
            :style="!stepData.inputImgUrl ? 'border: dotted;opacity: 0.7;height:350px' : 'color: transparent;'"
            @click="clickOnImage"
          >
            <v-fab-transition>
              <v-btn
                color="primary"
                dark
                absolute
                top
                left
                fab
                x-small
                style="left: -5px;"
                @click.stop="deleteImage"
              >
                <v-icon
                  size="25"
                >
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-fab-transition>
            <div
              v-if="stepData.inputImgUrl"
            >
              <v-img
                :src="stepData.inputImgUrl"
                height="300"
                contain
              >
              </v-img>
            </div>
            <div
              v-if="!stepData.inputImgUrl"
              style="width: 100vw;"
            >
              <v-img
                :src="require('@/views/images/nonPictureIcon.png')"
                style="width: 12%; height: 12%;opacity: 0.2"
                height="300"
                contain
                class="centerImg"
              >
              </v-img>
              <v-btn
                rounded
                color="primary"
                class="mt-0"
                outlined
              >
                {{ $t('UploadInvitation') }}
              </v-btn>
            </div>
          </v-card>

          <v-row
            justify="center"
            align="center"
          >
            <v-col
              md="6"
              sm="8"
            >
              <v-file-input
                id="eventImg"
                v-model="invitation"
                accept="image/*"
                style="display: none"
                @change="inputImgChange"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- Action Buttons -->
    <div
      class="d-flex justify-space-between"
    >
      <v-btn
        outlined
        rounded
        @click="() => moveToPrevious()"
        @submit.prevent="onSubmit"
      >
        {{ $t('Previous') }}
      </v-btn>

      <v-btn
        color="primary"
        rounded
        @click="() => validateForm()"
      >
        {{ $t('Finish') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { mdiClose, mdiCloudUploadOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import usePopupWithHash from '@/composables/usePopupWithHash'

export default {
  props: {
    eventData: {
      default: null,
      type: Object,
    },
    moveToNextStep: {
      type: Function,
      default: null,
    },
    moveToPreviousStep: {
      type: Function,
      default: null,
    },
    newEvent: {
      type: Boolean,
    },
  },
  setup(props) {
    const step3Form = ref(null)
    const invitation = ref(null)
    const fileUpload = ref(null)
    const { isPopupOpen: showCropper } = usePopupWithHash('upload-invitation-image')

    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))

    // Reference to cropper element
    const cropper = ref(false)
    const zoomLevel = ref(1)
    const applyZoom = () => {
      if (cropper.value) {
        const imageElement = cropper.value.$el.querySelector('.slim-cropper-image')
        if (imageElement) {
          imageElement.style.transform = `scale(${zoomLevel.value})`
        }
      }
    }
    const handleMouseWheel = event => {
      event.preventDefault()
      const delta = Math.max(-1, Math.min(1, event.deltaY))
      const zoomAmount = delta * 0.1 // Adjust the zoom increment as per your requirement
      zoomLevel.value += zoomAmount
      applyZoom()
    }

    const stepData = ref({
      inputImgUrl: props.eventData.image
        ? `${process.env.VUE_APP_LOCAL_HOST}event/images/${props.eventData.image}`
        : '',
    })

    const clickOnImage = () => {
      const eventImg = document.getElementById('eventImg')
      eventImg.click()
    }

    const getObjectUrl = file => {
      let url
      if (window.createObjectURL) {
        // basic
        url = window.createObjectURL(file)
      } else if (window.URL) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file)
      } else if (window.webkitURL) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
      }

      return url
    }
    const inputImgChange = file => {
      if (!file) {
        stepData.value.inputImgUrl = null
        fileUpload.value = null
      } else {
        stepData.value.inputImgUrl = getObjectUrl(file)
        showCropper.value = true
      }
    }

    const hideCrop = () => {
      showCropper.value = false
      stepData.value.inputImgUrl = null
      fileUpload.value = null
      invitation.value = null
      URL.revokeObjectURL(stepData.value.inputImgUrl)
    }
    const submitCrop = async () => {
      showCropper.value = false
      const img = await cropper.value.getCroppedBlob()
      fileUpload.value = new File([img], 'invitationImg.png')

      stepData.value.inputImgUrl = getObjectUrl(img)
    }

    const validateForm = () => {
      if (step3Form.value.validate()) {
        props.moveToNextStep({ imageFile: fileUpload.value })
      }
    }
    const moveToPrevious = () => {
      props.moveToPreviousStep()
    }

    const deleteImage = () => {
      // if edit event settings, check if the event have already image
      if (!props.newEvent) {
        // have image ? delete it
        if (store.state.eventData.image !== '') {
          store
            .dispatch('deleteImage', { id: store.state.eventData.id })
            .then(() => {
              // eslint-disable-next-line no-param-reassign
              props.eventData.image = ''
            })
            .catch(() => showToast())
        }
      }

      // delete localy image display
      hideCrop()
    }

    return {
      step3Form,
      validateForm,
      stepData,
      clickOnImage,
      inputImgChange,
      fileUpload,
      getObjectUrl,
      showCropper,
      cropper,
      invitation,
      hideCrop,
      submitCrop,
      moveToPrevious,
      handleMouseWheel,
      applyZoom,
      deleteImage,

      icons: {
        mdiCloudUploadOutline,
        mdiClose,
      },
    }
  },
}
</script>
<style scoped>
.centerImg {
  place-items: center;
  display: flex;
}
.slimCrop {
  max-width: 100%;
  height: 400px;
  z-index: 1;
  position: relative;
  margin: auto;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
}
.btn-crop {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
}

.slim-fade-enter-active,
.slim-fade-leave-active {
  transition: all 0.4s ease;
}
.slim-fade-enter,
.slim-fade-leave-to {
  opacity: 0;
  transform-origin: top;
  transform: translateY(100%);
}
</style>
