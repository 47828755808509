export default function useTransport() {
  const transportTypes = {
    NO_NEED_TO_ASK: 0,
    SOME_GUESTS: 1,
    ALL_GUESTS: 2,
  }
  const transportOptions = [
    { name: 'no_need_to_ask', value: transportTypes.NO_NEED_TO_ASK },
    { name: 'some_guests', value: transportTypes.SOME_GUESTS },
    { name: 'all_guests', value: transportTypes.ALL_GUESTS },
  ]

  return {
    transportOptions,
    transportTypes,
  }
}
