<template>
  <div
    v-if="loading"
    class="d-flex justify-center"
  >
    <!-- Loading circle -->
    <v-progress-circular
      :size="50"
      indeterminate
      color="primary"
    >
    </v-progress-circular>
  </div>
  <div v-else>
    <v-app-bar
      color="transparent"
      elevation="0"
      style="height: 40px !important"
    >
      <AppBarI18n></AppBarI18n>
      <v-spacer></v-spacer>
      <v-btn
        icon
        :to="{name:'manage-events'}"
      >
        <v-icon>{{ icons.mdiClose }}</v-icon>
      </v-btn>
    </v-app-bar>
    <p class="text-2xl mt-0 mb-5 text-center">
      {{ $t('NewEvent') }}
    </p>

    <NewEventStepper
      :event-data="userData"
      :new-event="true"
      @submit="postNewEventData"
    ></NewEventStepper>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import NewEventStepper from '@/components/EventDetails/Form/NewEventStepper.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'

export default {
  name: 'CreateNewEvent',
  components: {
    NewEventStepper,
    AppBarI18n,
  },
  setup() {
    const userData = {
      location: '',
      image: '',
      languages: [],
      langEventName: [],
      transports: [],
      date: '',
      defaultLang: '',
      transport: 0,
      specialMeals: [0],
    }
    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorNewEvent'))
    const loading = ref(false)

    // Track start of event creation
    Vue.prototype.$gtag.event('Start_Create_Event', {
      event_category: 'Event Creation',
      event_label: 'Start',
    })

    const postNewEventData = (item, errorHandler) => {
      loading.value = true
      store
        .dispatch('newEvent', item)
        .then(res => {
          // Track successful event creation
          Vue.prototype.$gtag.event('Complete_Create_Event', {
            event_category: 'Event Creation',
            event_label: 'Complete',
          })

          // Router to guest list
          router.push({ name: 'apps-user-list', params: { id: res.data.id } })
        })
        .catch(() => {
          showToast()
          errorHandler()
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      postNewEventData,
      userData,
      loading,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
